import MicroModal from 'micromodal';

import { ModalCreateProp } from '@store/types';

import './Modal.scss';

const ModalCreate = ({ idModal, content, title }: ModalCreateProp) => {
    const modalContainer: HTMLElement = document.createElement('div');
    modalContainer.innerHTML = `
        <div
            class="modal micromodal-slide"
            id="${idModal}" 
            aria-hidden="true"
        >
            <div class="modal__inner">
                <div class="modal__overlay" tabindex="-1" data-micromodal-close></div>
                <div
                    class="modal__container" 
                    role="dialog" 
                    aria-modal="true" 
                    aria-labelledby="${idModal}-title" 
                    aria-describedby="${idModal}-content"
                >
                    <button 
                        class="modal__close icon icon-cross" 
                        aria-label="Close modal" 
                        aria-controls="${idModal}" 
                        data-micromodal-close
                    ></button>
                    ${
                        title
                            ? `<header class="modal__header"> 
                                    <h2 class="modal__title" id="${idModal}-title">${title}</h2>
                                </header>`
                            : ``
                    }
                    <div class="modal__content" id="${idModal}-content"> 
                        ${content}
                    </div>
                </div>
            </div>
        </div>`;

    document.getElementById('modal-popup-container').appendChild(modalContainer);

    return modalContainer;
};

export { ModalCreate };

export default MicroModal;
